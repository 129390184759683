<template>
  <v-container class="pb-2">
    <v-row>
      <v-col cols="1" align="right" offset="11">
        <v-btn icon @click.stop="toggleMenu" class="float-right">
          <v-icon x-large>mdi-menu</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <Menu ref="refMenu"></Menu>
    <h2 class="text-center">Правила защиты информации о пользователях сайта</h2>

    <h3>1. Общие положения</h3>

    <p>1.1. Настоящие Правила являются официальным документом ИП Самойлов Евгений Вольдемарович, ИНН 616605380104, ОГРНИП 308616614400023, расположенного по адресу 344029, г. Ростов-на-Дону, ул. Металлургическая, 15, кв 61 (далее – Администрация Сайта), и определяют порядок обработки и защиты информации о физических лицах, пользующихся услугами интернет-сайта http://trashpanda.ru/ (далее – Сайт) и его сервисов (далее – Пользователи).</p>

    <p>1.2. Целью настоящих Правил является обеспечение надлежащей защиты информации о пользователях, в том числе их персональных данных, от несанкционированного доступа и разглашения.</p>

    <p>1.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о пользователях Сайта, регулируются настоящими Правилами, иными официальными документами Администрации Сайта и действующим законодательством Российской Федерации.</p>

    <p>1.4. Действующая редакция Правил, являющихся публичным документом, доступна любому пользователю сети Интернет при переходе по ссылке http://trashpanda.ru/ Администрация Сайта вправе вносить изменения в настоящие Правила. При внесении изменений в Правила Администрация Сайта уведомляет об этом пользователей путем размещения новой редакции Правил на Сайте по постоянному адресу http://app.trashpanda.ru/privacy не позднее, чем за 10 дней до вступления в силу соответствующих изменений. Предыдущие редакции Правил хранятся в архиве документации Администрации Сайта.</p>

    <p>1.6. Используя Сайт, Пользователь выражает свое согласие с условиями настоящих Правил.</p>

    <p>1.7. В случае несогласия Пользователя с условиями настоящих Правил использование Сайта и его сервисов должно быть немедленно прекращено.</p>

    <h3>2. Условия пользования Сайтом</h3>

    <p>2.1. Оказывая услуги по использованию Сайта и его сервисов (далее – Услуги Сайта), Администрация Сайта, действуя разумно и добросовестно, считает, что Пользователь:</p>

    <p>- обладает всеми необходимыми правами, позволяющими ему использовать настоящий Сайт;

    <br>- указывает достоверную информацию о себе в объемах, необходимых для пользования Услугами Сайта;

    <br>- осознает, что информация на Сайте, размещаемая Пользователем о себе, может становиться доступной для других Пользователей Сайта и пользователей Интернета, может быть скопирована и распространена такими пользователями;

    <br>- осознает, что некоторые виды информации, переданные им другим Пользователям, не могут быть удалены самим Пользователем;

    <br>- ознакомлен с настоящими Правилами, выражает свое согласие с ними и принимает на себя указанные в них права и обязанности.</p>

    <p>2.2. Администрация Сайта не проверяет достоверность получаемой (собираемой) информации о пользователях, за исключением случаев, когда такая проверка необходима в целях исполнения Администрацией Сайта обязательств перед пользователем</p>

    <h3>3. Цели обработки информации</h3>

    <p>Администрация Сайта осуществляет обработку информации о Пользователях, в том числе их персональных данных, в целях выполнения обязательств Администрации Сайта перед Пользователями в отношении использования Сайта и его сервисов.</p>

    <h3>4. Состав информации о пользователях</h3>

    <p>4.1. Персональные данные Пользователей Персональные данные Пользователей включают в себя:</p>

    <p>4.1.1. предоставляемые Пользователями и минимально необходимые для использования Сайте: имя, номер мобильного телефона</p>

    <p>4.2. Иная информация о Пользователях, обрабатываемая Администрацией Сайта Администрация Сайта может также обрабатывать иную информацию о Пользователях, которая включает в себя:</p>

    <p>4.2.1. - дополнительные данные, получаемые при доступе к Сайту, включающие в себя данные о технических средствах (устройствах), технологическом взаимодействии с Сайтом (в т.ч. IP-адрес хоста, вид операционной системы пользователя, тип браузера, географическое положение, поставщик услуг Интернета, данные из адресной книги, данные, полученные в результате доступа к камере, микрофону и т.п. устройств), и последующих действиях Пользователя на Сайте. Информация, содержащая историю посещения Сайта пользователем, доступна Пользователю в разделе «Настройки / Безопасность»;</p>

    <p>4.2.2. - информация, автоматически получаемая при доступе к Сайту с использованием закладок (cookies)</p>

    <h3>5. Обработка информации о пользователях</h3>

    <p>5.1. Обработка персональных данных осуществляется на основе принципов:</p>

    <p>а) законности целей и способов обработки персональных данных;
      <br>б) добросовестности;
      <br>в) соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Администрации Сайта;
      <br>г) соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;
      <br>д) недопустимости объединения созданных для несовместимых между собой целей баз данных, содержащих персональные данные.</p>

    <p>5.1.1. Условия и цели обработки персональных данных Администрация Сайта осуществляет обработку персональных данных пользователя в целях исполнения договора между Администрацией Сайта и Пользователем на оказание Услуг Сайта. В силу статьи 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» отдельное согласие пользователя на обработку его персональных данных не требуется. В силу п.п. 2 п. 2 статьи 22 указанного закона Администрация Сайта вправе осуществлять обработку персональных данных без уведомления уполномоченного органа по защите прав субъектов персональных данных.</p>

    <p>5.1.2. Сбор персональных данных Сбор персональных данных Пользователя осуществляется на Сайте при оформлении заявки на обратный звонок.</p>

    <p>5.1.3. Хранение и использование персональных данных Персональные данные пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.</p>

    <p>5.1.4. Передача персональных данных</p>

    <p>Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящими Правилами.</p>
      <p>При указании пользователя или при наличии согласия пользователя возможна передача персональных данных пользователя третьим лицам-контрагентам Администрации Сайта с условием принятия такими контрагентами обязательств по обеспечению конфиденциальности полученной информации, в частности, при использовании приложений.</p>
      <p>Приложения, используемые пользователями на Сайте, размещаются и поддерживаются третьими лицами (разработчиками), которые действуют независимо от Администрации Сайта и не выступают от имени или по поручению Администрации Сайта. Пользователи обязаны самостоятельно ознакомиться с правилами оказания услуг и политикой защиты персональных данных таких третьих лиц (разработчиков) до начала использования соответствующих приложений.</p>
      <p>Действия таких третьих лиц (разработчиков) регулируются официальным документом Администрации Сайта Условиями размещения приложений.</p>
      <p>Предоставление персональных данных Пользователей по запросу государственных органов (органов местного самоуправления) осуществляется в порядке, предусмотренном законодательством.</p>
      <p>В целях исполнения соглашения между Пользователем и Администрацией Сайта и предоставления Пользователю доступа к использованию функционала Сайта, Администрация Сайта развивает предоставляемые сервисы и продукты, разрабатывает и внедряет новые сервисы и продукты, оптимизирует качество сервисов и продуктов, совершенствует доступный функционал Сайта и сервисов. Для обеспечения реализации указанных целей Пользователь соглашается на осуществление Администрацией Сайта с соблюдением применимого законодательства сбора, хранения, накопления, систематизации, извлечения, сопоставления, использования, наполнения (уточнения) их данных, а также на получение и передачу аффилированным лицам и партнерам результатов автоматизированной обработки таких данных с применением различных моделей оценки информации, в виде целочисленных и/или текстовых значений и идентификаторов, соответствующих заданным в запросах оценочным критериям, для обработки данных Администрацией Сайта и/или лицами, указанными в настоящем пункте</p>

    <h3>6. Меры по защите информации о Пользователях</h3>

    <p>6.1. Администрация Сайта принимает технические и организационно-правовые меры в целях обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий.</p>

    <h3>7. Ограничение действия Правил</h3>

    <p>Действие настоящих Правил не распространяется на действия и интернет-ресурсы третьих лиц. Администрация Сайта не несет ответственности за действия третьих лиц, получивших в результате использования Интернета или Услуг Сайта доступ к информации о Пользователе в соответствии с выбранным Пользователем уровнем конфиденциальности, за последствия использования информации, которая, в силу природы Сайта, доступна любому пользователю сети Интернет. Администрация Сайта рекомендует Пользователям ответственно подходить к решению вопроса об объеме информации о себе, размещаемой на Сайте.</p>

    <h3>8. Обращения пользователей</h3>

    <p>8.1. Пользователи вправе направлять Администрации Сайта свои запросы, в том числе запросы относительно использования их персональных данных по адресу электронной почты: ugmet@ugmet.ru</p>

    <p>8.2. Запрос, направляемый пользователем, должен содержать следующую информацию:</p>

    <p>- номер основного документа, удостоверяющего личность пользователя или его представителя;

      <br>- сведения о дате выдачи указанного документа и выдавшем его органе;

      <br>- подпись пользователя или его представителя.</p>

    <p>8.3. Администрация Сайта обязуется рассмотреть и направить ответ на поступивший запрос пользователя в течение 30 дней с момента поступления обращения.</p>

    <p>8.4. Вся корреспонденция, полученная Администрацией Сайта от пользователей (обращения в письменной или электронной форме), относится к информации ограниченного доступа и не разглашается без письменного согласия Пользователя. Персональные данные и иная информация о Пользователе, направившем запрос, не могут быть без специального согласия Пользователя использованы иначе, как для ответа по теме полученного запроса или в случаях, прямо предусмотренных законодательством.</p>

    <v-row>
      <v-col class="d-flex justify-center">
        <router-link to="/" class="text-decoration-none d-block">
          <v-btn
            large
            elevation="2"
            dark
            color="orange lighten-1"
            class="mt-10 custom-btn"
          >
            На главную
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <br><br><br><br><br><br>
  </v-container>
</template>
<script>
import Menu from '../components/Menu'

export default {
  components: { Menu },
  props: ['date', 'token'],
  data: () => ({
    orders: []
  }),
  created () {
    this.orders = this.$ls.get('ordersHistory').reverse()
  },
  methods: {
    toggleMenu () {
      this.$refs.refMenu.toggle()
    },
    open (item) {
      this.$router.push('/result/' + item.token + '/' + item.date)
    }
  }
}
</script>
<style>
h1 {
  line-height: 1em;
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
}

h3 {
  //line-height: 1em;
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  //font-weight: normal;
}
</style>
